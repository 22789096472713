<template>

<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <solution-common :newsItem="newsItem" />
</div>
</template>

<script>

import solutionCommon from "@/views/solutionDetail/solutionCommon";
import {getNews} from "@/network/main";

export default {
  name: "solutionMore",
  components:{
    solutionCommon
  },
  data() {
    return {
      id: null,
      banner:{},
      newsItem:{},
     }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    getNews(this.id).then(res => {
     this.banner = res.data.data.banner;
     this.newsItem = res.data.data.news;
    })
  },

}
</script>

<style scoped>
.solution-more h1{
  padding-bottom: 20px;
}

</style>